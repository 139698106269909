// @ts-strict-ignore
import React, { useState } from "react"

import {
  differenceInDays,
  formatDistanceStrict,
  isBefore,
  subHours,
} from "date-fns"
import { Form, Formik, FormikProps } from "formik"
import * as Yup from "yup"

import {
  Alert,
  Box,
  Button,
  Collapse,
  Stack,
  Text,
} from "@kiwicom/orbit-components"
import AlertIcon from "@kiwicom/orbit-components/lib/icons/Alert"

import {
  createStopReplacementPattern,
  deleteStopReplacementPattern,
  updateStopReplacementPattern,
} from "api/disruptions"
import { EmberApiError } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { EmberSelectOption } from "components/generic/ember-select"
import {
  EmberCheckboxGroup,
  EmberDateInput,
} from "components/generic/formik-inputs"
import { EmberTextInput } from "components/generic/formik-inputs"
import LocationPicker from "components/search-bar/location-picker"

import { Location, Route } from "types/location"
import { StopReplacementPattern } from "types/location"

import { DAYS_OF_WEEK } from "../types"
import { RouteSelector } from "./route-selector"

const getInitialFormValues = (
  pattern: StopReplacementPattern | null,
  locationOptions: Location[]
): StopReplacementPatternFormValues => {
  if (pattern == null) {
    return {
      startTime: "",
      endTime: "",
      startTimeWithinDay: "",
      endTimeWithinDay: "",
      originalLocation: null,
      replacementLocation: null,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7].map((day) => day.toString()),
      routes: [],
      routeSelection: "all-routes",
    }
  }

  const originalLocation = locationOptions.find(
    (location) => location.id == pattern.originalLocationId
  )
  const replacementLocation = locationOptions.find(
    (location) => location.id == pattern.replacementLocationId
  )
  return {
    startTime: pattern.startTime ?? "",
    endTime: pattern.endTime ?? "",
    startTimeWithinDay: pattern.startTimeWithinDay ?? "",
    endTimeWithinDay: pattern.endTimeWithinDay ?? "",
    originalLocation: locationAsEmberSelectOption(originalLocation),
    replacementLocation: locationAsEmberSelectOption(replacementLocation),
    daysOfWeek: pattern.daysOfWeek.map((day) => day.toString()),
    routes: pattern.routes.map((route) => route.id.toString()),
    routeSelection:
      pattern.routes.length == 0 ? "all-routes" : "specific-routes",
  }
}

const locationAsEmberSelectOption = (location: Location): EmberSelectOption => {
  return {
    id: location.id.toString(),
    title: location.regionName,
    description: location.detailedName,
  }
}

interface StopReplacementPatternFormValues {
  originalLocation: EmberSelectOption
  replacementLocation: EmberSelectOption
  daysOfWeek: string[]
  startTime: string
  endTime: string
  startTimeWithinDay: string
  endTimeWithinDay: string
  routes: string[]
  routeSelection: "all-routes" | "specific-routes"
}

interface FormBodyProps {
  formikProps: FormikProps<StopReplacementPatternFormValues>
  globalError: EmberApiError | null
  isEditing: boolean
  setIsDeleting: (isDeleting: boolean) => void
  locationOptions: Location[]
  routes: Route[]
}

const FormBody = ({
  formikProps,
  globalError,
  isEditing,
  setIsDeleting,
  locationOptions,
  routes,
}: FormBodyProps) => {
  const { errors, dirty, isSubmitting, setFieldValue, values } = formikProps
  const submitDisabled = isSubmitting || !dirty
  const [collapseOpen, setCollapseOpen] = useState(false)

  const originalLocation = locationOptions.find(
    (location) =>
      location.id ===
      (values.originalLocation && parseInt(values.originalLocation.id))
  )
  const filteredLocations = originalLocation
    ? locationOptions.filter(
        (location) =>
          location.areaId === originalLocation.areaId &&
          location.id !== originalLocation.id
      )
    : locationOptions

  return (
    <Stack spacing="large">
      <Form>
        <Stack spacing="large">
          <Box margin="none">
            <Stack direction="column">
              <Stack spaceAfter="none">
                <LocationPicker
                  label="Original Location"
                  defaultValue={values.originalLocation?.description || ""}
                  locations={locationOptions}
                  onChange={(value: Location) => {
                    setFieldValue(
                      "originalLocation",
                      locationAsEmberSelectOption(value)
                    )
                    setFieldValue("replacementLocation", null)
                  }}
                  inputProps={{
                    error: errors.originalLocation,
                    disabled: isEditing,
                  }}
                />
              </Stack>
              <Stack spacing="none" spaceAfter="large">
                <LocationPicker
                  label="Replacement Location"
                  defaultValue={values.replacementLocation?.description || ""}
                  locations={filteredLocations}
                  onChange={(value: Location) => {
                    setFieldValue(
                      "replacementLocation",
                      locationAsEmberSelectOption(value)
                    )
                  }}
                  inputProps={{
                    error: errors.replacementLocation,
                    help: "Replacement location must belong to the same stop area as the original location.",
                    disabled: isEditing,
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              direction="column"
              largeMobile={{ direction: "row", align: "end" }}
              spaceAfter="large"
            >
              <EmberDateInput
                name="startTime"
                label="Start Time"
                showTime
                dataTest="scheduled-start"
                actionLink={{
                  label: "Set Current Time",
                  onClick: () => setFieldValue("startTime", new Date()),
                }}
              />
              <EmberDateInput
                name="endTime"
                label="End Time (Optional)"
                showTime
                dataTest="scheduled-end"
              />
            </Stack>
            {values.startTime &&
              values.endTime &&
              isBefore(new Date(values.startTime), new Date(values.endTime)) &&
              differenceInDays(
                new Date(values.endTime),
                new Date(values.startTime)
              ) > 7 && (
                <Stack spaceAfter="medium">
                  <Alert type="warning">
                    Duration:{" "}
                    {formatDistanceStrict(
                      new Date(values.startTime),
                      new Date(values.endTime)
                    )}
                  </Alert>
                </Stack>
              )}
            {values.startTime && !values.endTime && (
              <Stack spaceAfter="medium">
                <Alert type="warning">
                  The stop replacement will be applied indefinitely without an
                  end time.
                </Alert>
              </Stack>
            )}
            {values.startTime &&
              isBefore(new Date(values.startTime), subHours(new Date(), 1)) && (
                <Stack spaceAfter="medium">
                  <Alert type="warning">The start time is in the past.</Alert>
                </Stack>
              )}
            <Stack direction="row" spaceAfter="large">
              <EmberCheckboxGroup
                name="daysOfWeek"
                label="Days of Week"
                options={DAYS_OF_WEEK.map((day, index) => ({
                  value: (index + 1).toString(),
                  label: day,
                }))}
              />
            </Stack>
            <Stack direction="row" spaceAfter="large">
              <EmberTextInput
                name="startTimeWithinDay"
                label="Start Time Within Day (Optional)"
                // @ts-ignore Kiwi-Orbit input type doesn't have "time" even though it's valid
                type="time"
                dataTest="start-time-within-day"
              />
              <EmberTextInput
                name="endTimeWithinDay"
                label="End Time Within Day (Optional)"
                // @ts-ignore Kiwi-Orbit input type doesn't have "time" even though it's valid
                type="time"
                dataTest="end-time-within-day"
              />
            </Stack>
            <Stack spaceAfter="none">
              <Collapse
                label={
                  collapseOpen ? "Hide additional fields" : "View all fields"
                }
                expanded={collapseOpen}
                onClick={() => {
                  setCollapseOpen(!collapseOpen)
                }}
              >
                <RouteSelector
                  allRoutes={routes}
                  selectedRoutes={values.routes}
                  setFieldValue={setFieldValue}
                />
              </Collapse>
            </Stack>
          </Box>
          <Stack spacing="small">
            <Button
              disabled={submitDisabled}
              loading={isSubmitting}
              fullWidth={true}
              submit={true}
              dataTest={`submit-changes-${
                submitDisabled ? "disabled" : "enabled"
              }`}
            >
              Save and Inform Passengers
            </Button>
            {isEditing && (
              <Button
                fullWidth
                type="secondary"
                onClick={() => setIsDeleting(true)}
              >
                Delete Stop Replacement
              </Button>
            )}
          </Stack>
        </Stack>
      </Form>
      {globalError && (
        <Alert type="critical" icon={<AlertIcon />} dataTest="bottom-error">
          <Text>{globalError.message}</Text>
        </Alert>
      )}
    </Stack>
  )
}

interface StopReplacementPatternModalProps {
  disruptionUid: string
  pattern: StopReplacementPattern | null
  handleClose: () => void
  handleUpdate: () => void
  locationOptions: Location[]
  routes: Route[]
}

const StopReplacementPatternModal = ({
  disruptionUid,
  pattern,
  handleClose,
  handleUpdate,
  locationOptions,
  routes,
}: StopReplacementPatternModalProps) => {
  const [globalError, setGlobalError] = useState<EmberApiError>()
  const [isComplete, setComplete] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const initialValues = getInitialFormValues(pattern, locationOptions)

  const title = pattern !== null ? "Edit Stop Replacement" : "Replace Stop"

  const handleDelete = () => {
    deleteStopReplacementPattern({
      patternUid: pattern.uid,
      onSuccess: () => {
        setComplete(true)
        handleUpdate()
      },
      onError: () => {
        setGlobalError({
          message: "Failed to delete stop replacement",
        })
      },
    })
  }

  return (
    <EmberModal
      size="small"
      onClose={isComplete ? handleUpdate : handleClose}
      dataTest="stop-replacement-pattern-change-modal"
      preventOverlayClose={true}
    >
      <EmberModalHeader title={title} />
      <EmberModalSection>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            originalLocation: Yup.object()
              .nullable()
              .required("Original Location is a required field"),
            replacementLocation: Yup.object()
              .nullable()
              .required("Replacement Location is a required field"),
            startTime: Yup.date()
              .required("Start Time is a required field")
              .test(
                "is-before-end-time",
                "Start Time must be before End Time",
                function (value) {
                  const { endTime } = this.parent
                  return !endTime || new Date(value) < new Date(endTime)
                }
              ),
            startTimeWithinDay: Yup.string()
              .test(
                "both-or-none",
                "Both Start Time Within Day and End Time Within Day must be set",
                function () {
                  const { startTimeWithinDay, endTimeWithinDay } = this.parent
                  return (
                    (!startTimeWithinDay && !endTimeWithinDay) ||
                    (startTimeWithinDay && endTimeWithinDay)
                  )
                }
              )
              .test(
                "different-values",
                "Start Time Within Day must be different from End Time Within Day",
                function (value) {
                  const { endTimeWithinDay } = this.parent
                  return (
                    !value || !endTimeWithinDay || value !== endTimeWithinDay
                  )
                }
              ),
            endTimeWithinDay: Yup.string()
              .test(
                "both-or-none",
                "Both Start Time Within Day and End Time Within Day must be set",
                function () {
                  const { startTimeWithinDay, endTimeWithinDay } = this.parent
                  return (
                    (!startTimeWithinDay && !endTimeWithinDay) ||
                    (startTimeWithinDay && endTimeWithinDay)
                  )
                }
              )
              .test(
                "different-values",
                "End Time Within Day must be different from Start Time Within Day",
                function (value) {
                  const { startTimeWithinDay } = this.parent
                  return (
                    !value ||
                    !startTimeWithinDay ||
                    value !== startTimeWithinDay
                  )
                }
              ),
            daysOfWeek: Yup.array()
              .min(1, "At least one day of week is required")
              .required("At least one day of week is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              startTime: values.startTime || null,
              endTime: values.endTime || null,
              startTimeWithinDay: values.startTimeWithinDay || null,
              endTimeWithinDay: values.endTimeWithinDay || null,
              daysOfWeek: values.daysOfWeek
                .map((day) => parseInt(day))
                .sort((a, b) => a - b),
              originalLocationId:
                pattern === null
                  ? parseInt(values.originalLocation.id)
                  : undefined,
              replacementLocationId:
                pattern === null
                  ? parseInt(values.replacementLocation.id)
                  : undefined,
              routes:
                values.routeSelection == "all-routes"
                  ? []
                  : values.routes.map((route) => parseInt(route)),
            }
            if (pattern === null) {
              createStopReplacementPattern({
                disruptionUid,
                pattern: payload,
                onSuccess: () => {
                  setComplete(true)
                  setSubmitting(false)
                },
                onError: ({ message }) => {
                  setGlobalError({
                    message: `Failed to create stop replacement: ${message}`,
                  })
                  setSubmitting(false)
                },
              })
            } else {
              updateStopReplacementPattern({
                patternUid: pattern.uid,
                pattern: payload,
                onSuccess: () => {
                  setComplete(true)
                  setSubmitting(false)
                },
                onError: ({ message }) => {
                  setGlobalError({
                    message: `Failed to update stop replacement: ${message}`,
                  })
                  setSubmitting(false)
                },
              })
            }
          }}
        >
          {(formikProps: FormikProps<StopReplacementPatternFormValues>) =>
            isComplete ? (
              <SuccessScreen handleClose={handleUpdate} />
            ) : isDeleting ? (
              <DeleteConfirmation
                handleClose={handleClose}
                handleDelete={handleDelete}
              />
            ) : (
              <FormBody
                formikProps={formikProps}
                globalError={globalError}
                setIsDeleting={setIsDeleting}
                isEditing={pattern != null}
                locationOptions={locationOptions}
                routes={routes}
              />
            )
          }
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}

interface SuccessScreenProps {
  handleClose: () => void
}

const SuccessScreen = ({ handleClose }: SuccessScreenProps) => (
  <Stack dataTest="completion-message" spacing="XLarge">
    <Stack>
      <Text>
        The stop replacement has been applied to trips and affected passengers
        have been notified.
      </Text>
    </Stack>
    <Button fullWidth={true} onClick={handleClose}>
      OK
    </Button>
  </Stack>
)

interface DeleteConfirmationProps {
  handleClose: () => void
  handleDelete: () => void
}

const DeleteConfirmation = ({
  handleClose,
  handleDelete,
}: DeleteConfirmationProps) => (
  <Stack dataTest="delete-confirmation-message" spacing="XLarge">
    <Stack>
      <Text>
        Are you sure you want to delete this stop replacement? The passengers
        will be notified again about the stop change.
      </Text>
    </Stack>
    <Stack direction="row" justify="between">
      <Button type="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button type="critical" onClick={handleDelete}>
        Delete
      </Button>
    </Stack>
  </Stack>
)

export default StopReplacementPatternModal
