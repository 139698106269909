// @ts-strict-ignore
import React, { useState } from "react"

import { isBefore, subHours } from "date-fns"
import { Form, Formik, FormikProps } from "formik"
import * as Yup from "yup"

import {
  Alert,
  Box,
  Button,
  InputField,
  Stack,
  Text,
} from "@kiwicom/orbit-components"
import AlertIcon from "@kiwicom/orbit-components/lib/icons/Alert"

import {
  createDisruption,
  deleteDisruption,
  updateDisruption,
} from "api/disruptions"
import { EmberApiError } from "api/errors"

import { filteredDisruptionReasons } from "components/disruptions/types"
import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { EmberSelectOption } from "components/generic/ember-select"
import {
  EmberDateInput,
  EmberFormSelect,
} from "components/generic/formik-inputs"
import { EmberTextArea } from "components/generic/formik-inputs"
import VisibilityRestricter from "components/generic/visibility-restricter"

import { Disruption } from "types/location"
import { UserGroup } from "types/person"
import { DisruptionReason } from "types/suspension"

import { snakeCaseToTitleCase } from "utils/string-utils"

const getInitialFormValues = (
  disruption: Disruption | null
): DisruptionFormValues => {
  if (disruption == null) {
    return {
      title: "",
      description: "",
      startTime: "",
      endTime: "",
      cause: { id: DisruptionReason.ROAD_CLOSURE, title: "Road Closure" },
      latitude: "",
      longitude: "",
    }
  }
  return {
    title: disruption.title,
    description: disruption.description,
    startTime: disruption.startTime,
    endTime: disruption.endTime,
    cause: {
      id: disruption.cause,
      title: snakeCaseToTitleCase(disruption.cause),
    },
    latitude: disruption.latitude.toString(),
    longitude: disruption.longitude.toString(),
  }
}

interface DisruptionFormValues {
  title: string
  description: string
  startTime: string
  endTime: string
  cause: EmberSelectOption
  latitude: string
  longitude: string
}

interface FormBodyProps {
  formikProps: FormikProps<DisruptionFormValues>
  globalError: EmberApiError | null
  isEditing: boolean
  allowDeletion: boolean
  setIsDeleting: (isDeleting: boolean) => void
}

const FormBody = ({
  formikProps,
  globalError,
  isEditing,
  allowDeletion,
  setIsDeleting,
}: FormBodyProps) => {
  const { values, isSubmitting, setFieldValue, dirty } = formikProps
  const { title, description } = values

  const submitDisabled = isSubmitting || !dirty

  return (
    <Stack spacing="large">
      <Form>
        <Stack spacing="large">
          <InputField
            name="title"
            label="Title"
            value={title}
            onChange={(e) => setFieldValue("title", e.target.value)}
            dataTest="title"
          />
          <EmberTextArea
            name="description"
            label="Description"
            help="Internal description of the disruption."
            helpClosable={false}
            value={description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            rows={4}
            dataTest="description"
          />
          <Box>
            <Stack
              direction="column"
              largeMobile={{ direction: "row", align: "end" }}
              spaceAfter="large"
            >
              <EmberDateInput
                name="startTime"
                label="Start Time"
                showTime
                dataTest="scheduled-start"
              />
              <EmberDateInput
                name="endTime"
                label="End Time (Optional)"
                showTime
                dataTest="scheduled-end"
              />
            </Stack>
          </Box>
          {values.startTime &&
            isBefore(new Date(values.startTime), subHours(new Date(), 1)) && (
              <Stack spaceAfter="large">
                <Alert type="warning">The start time is in the past.</Alert>
              </Stack>
            )}
          <EmberFormSelect
            name="cause"
            label="Cause"
            help="The cause is displayed in customer notifications."
            helpClosable={false}
            options={Object.values(filteredDisruptionReasons).map((cause) => ({
              id: cause,
              title: snakeCaseToTitleCase(cause),
            }))}
            dataTest="cause"
          />
          <Stack direction="row" spacing="large">
            <InputField
              name="latitude"
              label="Latitude"
              help="Co-ordinates can be found on Google Maps by right clicking on a map."
              helpClosable={false}
              error={formikProps.errors.latitude}
              value={formikProps.values.latitude}
              onChange={(e) => setFieldValue("latitude", e.target.value)}
              dataTest="latitude"
            />
            <InputField
              name="longitude"
              label="Longitude"
              help="Co-ordinates can be found on Google Maps by right clicking on a map."
              helpClosable={false}
              error={formikProps.errors.longitude}
              value={formikProps.values.longitude}
              onChange={(e) => setFieldValue("longitude", e.target.value)}
              dataTest="longitude"
            />
          </Stack>
          <Stack spacing="small">
            <Button
              disabled={submitDisabled}
              loading={isSubmitting}
              fullWidth={true}
              submit={true}
              dataTest={`submit-changes-${
                submitDisabled ? "disabled" : "enabled"
              }`}
            >
              Confirm
            </Button>
            <VisibilityRestricter showToGroups={[UserGroup.ADMINS]}>
              {isEditing && allowDeletion && (
                <Button
                  fullWidth
                  type="secondary"
                  onClick={() => setIsDeleting(true)}
                >
                  Delete Disruption
                </Button>
              )}
            </VisibilityRestricter>
          </Stack>
        </Stack>
      </Form>
      {globalError && (
        <Alert type="critical" icon={<AlertIcon />} dataTest="bottom-error">
          <Text>{globalError.message}</Text>
        </Alert>
      )}
    </Stack>
  )
}

interface DisruptionModalProps {
  disruption: Disruption | null
  handleClose: () => void
  handleUpdate: (disruption: Disruption | null) => void
}

const DisruptionModal = ({
  disruption,
  handleClose,
  handleUpdate,
}: DisruptionModalProps) => {
  const [globalError, setGlobalError] = useState<EmberApiError>()
  const [isComplete, setComplete] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const isEditing = disruption != null

  const initialValues = getInitialFormValues(disruption)

  const title = disruption == null ? "Create Disruption" : "Edit Disruption"

  const handleDelete = () => {
    deleteDisruption({
      disruptionUid: disruption.uid,
      onSuccess: () => {
        setComplete(true)
        handleUpdate(null)
      },
      onError: () => {
        setGlobalError({
          message: "Failed to delete disruption",
        })
      },
    })
  }

  return (
    <EmberModal
      size="small"
      onClose={handleClose}
      dataTest="stop-replacement-pattern-change-modal"
      preventOverlayClose={true}
    >
      <EmberModalHeader title={title} />
      <EmberModalSection>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            title: Yup.string().required("Please enter a title"),
            description: Yup.string().required("Please enter a description"),
            latitude: Yup.number()
              .required("Please enter a latitude")
              .min(-90, "Latitude must be between -90 and 90")
              .max(90, "Latitude must be between -90 and 90"),
            longitude: Yup.number()
              .required("Please enter a longitude")
              .min(-180, "Longitude must be between -180 and 180")
              .max(180, "Longitude must be between -180 and 180"),
            cause: Yup.object().nullable().required("Please select a cause"),
            startTime: Yup.date()
              .required("Start Time is a required field")
              .test(
                "is-before-end-time",
                "Start Time must be before End Time",
                function (value) {
                  const { endTime } = this.parent
                  return !endTime || new Date(value) < new Date(endTime)
                }
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              title: values.title,
              description: values.description,
              startTime: values.startTime,
              endTime: values.endTime || null,
              cause: values.cause.id,
              latitude: parseFloat(values.latitude),
              longitude: parseFloat(values.longitude),
            }
            if (disruption == null) {
              createDisruption({
                disruption: payload,
                onSuccess: (createdDisruption: Disruption) => {
                  setComplete(true)
                  setSubmitting(false)
                  handleUpdate(createdDisruption)
                  handleClose()
                },
                onError: ({ message }) => {
                  setGlobalError({
                    message: `Failed to create disruption: ${message}`,
                  })
                  setSubmitting(false)
                },
              })
            } else {
              updateDisruption({
                disruptionUid: disruption.uid,
                disruption: payload,
                onSuccess: (updatedDisruption: Disruption) => {
                  setComplete(true)
                  setSubmitting(false)
                  handleUpdate(updatedDisruption)
                  handleClose()
                },
                onError: ({ message }) => {
                  setGlobalError({
                    message: `Failed to update disruption: ${message}`,
                  })
                  setSubmitting(false)
                },
              })
            }
          }}
        >
          {(formikProps: FormikProps<DisruptionFormValues>) =>
            isComplete && !isEditing ? (
              <SuccessScreen handleClose={handleClose} />
            ) : isDeleting ? (
              <DeleteConfirmation
                handleClose={handleClose}
                handleDelete={handleDelete}
              />
            ) : (
              <FormBody
                formikProps={formikProps}
                globalError={globalError}
                isEditing={isEditing}
                allowDeletion={(disruption?.patterns || []).length === 0}
                setIsDeleting={setIsDeleting}
              />
            )
          }
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}

interface SuccessScreenProps {
  handleClose: () => void
}

const SuccessScreen = ({ handleClose }: SuccessScreenProps) => (
  <Stack dataTest="completion-message" spacing="XLarge">
    <Stack>
      <Text>The disruption has been created.</Text>
    </Stack>
    <Button fullWidth={true} onClick={handleClose}>
      OK
    </Button>
  </Stack>
)

interface DeleteConfirmationProps {
  handleClose: () => void
  handleDelete: () => void
}

const DeleteConfirmation = ({
  handleClose,
  handleDelete,
}: DeleteConfirmationProps) => (
  <Stack dataTest="delete-confirmation-message" spacing="XLarge">
    <Stack>
      <Text>Are you sure you want to delete this disruption?</Text>
    </Stack>
    <Stack direction="row" justify="between">
      <Button type="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button type="critical" onClick={handleDelete}>
        Delete
      </Button>
    </Stack>
  </Stack>
)

export default DisruptionModal
