// @ts-strict-ignore
import React from "react"

import mapboxgl from "mapbox-gl"
import MapGL, { Marker } from "react-map-gl"
import styled, { css } from "styled-components"

import { Stack, Text, TextLink } from "@kiwicom/orbit-components"
import mq from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { ConversationView } from "components/chat"
import { EmberInfoBox, InfoRow } from "components/generic/ember-card"

import {
  Disruption,
  Location,
  StopReplacementPattern,
  SuspensionPattern,
} from "types/location"

import { snakeCaseToTitleCase } from "utils/string-utils"

import { DisruptionActions } from "./disruption-actions"
import { formatActiveTimeRange } from "./utils"

// @ts-ignore Load in worker for mapbox-gl
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

interface InfoProps {
  disruption: Disruption
  allowAdminActions: boolean
  locations: Location[]
  setShowEditDisruptionModal: (show: boolean) => void
  openStopReplacementPatternModal?: () => void
  setEditStopReplacementPattern: (
    pattern: StopReplacementPattern | null
  ) => void
  openSuspensionPatternModal?: () => void
  setEditSuspensionPattern: (pattern: SuspensionPattern | null) => void
  refreshingDisruption: boolean
}

const MapWrapper = styled.div`
  margin: 0px -${(props) => props.theme.orbit.spaceMedium} !important;
  ${mq.largeMobile(css`
    margin: 0px !important;
  `)}

  padding-bottom: ${(props) => props.theme.orbit.spaceLarge};
`

const DisruptionInfo = ({
  disruption,
  allowAdminActions,
  locations,
  setShowEditDisruptionModal,
  openStopReplacementPatternModal,
  setEditStopReplacementPattern,
  openSuspensionPatternModal,
  setEditSuspensionPattern,
  refreshingDisruption,
}: InfoProps) => {
  return (
    <>
      <Stack direction="row" justify="between">
        <Stack direction="column" spacing="XXSmall" wrap inline shrink>
          <Text size="large" weight="bold">
            {disruption.title}
          </Text>
          <Text>
            {formatActiveTimeRange({
              startTime: disruption.startTime,
              endTime: disruption.endTime,
            })}
          </Text>
        </Stack>
      </Stack>
      <EmberInfoBox
        sectionTitle="Disruption Info"
        actions={
          allowAdminActions && [
            <TextLink
              key="Edit Trip"
              type="secondary"
              size="small"
              onClick={() => setShowEditDisruptionModal(true)}
            >
              Edit Disruption
            </TextLink>,
          ]
        }
      >
        <InfoRow
          label="Description"
          tooltip="Internal description of the disruption."
        >
          {disruption.description}
        </InfoRow>
        <InfoRow
          label="Cause"
          tooltip="The cause is displayed in customer notifications."
        >
          {snakeCaseToTitleCase(disruption.cause)}
        </InfoRow>
      </EmberInfoBox>
      <Stack direction="row">
        <Text weight="bold" uppercase>
          Disruption Location
        </Text>
      </Stack>
      <MapWrapper>
        <MapGL
          initialViewState={{
            longitude: disruption.longitude,
            latitude: disruption.latitude,
            zoom: 14,
          }}
          style={{ width: "100%", height: 300 }}
          mapboxAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          <Marker
            longitude={disruption.longitude}
            latitude={disruption.latitude}
          />
        </MapGL>
      </MapWrapper>
      {disruption.patterns && (
        <DisruptionActions
          refreshingDisruption={refreshingDisruption}
          patterns={disruption.patterns}
          suspensionPatterns={disruption.suspensionPatterns}
          allowAdminActions={allowAdminActions}
          locations={locations}
          openStopReplacementPatternModal={openStopReplacementPatternModal}
          setEditStopReplacementPattern={setEditStopReplacementPattern}
          openSuspensionPatternModal={openSuspensionPatternModal}
          setEditSuspensionPattern={setEditSuspensionPattern}
        />
      )}
      {disruption.conversation && (
        <Stack>
          <ConversationView conversation={disruption.conversation} />
        </Stack>
      )}
    </>
  )
}

export { DisruptionInfo }
