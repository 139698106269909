// @ts-strict-ignore
import React from "react"

import styled, { css } from "styled-components"

import {
  Badge,
  Button,
  Loading,
  Popover,
  Stack,
  Text,
} from "@kiwicom/orbit-components"
import { Plus } from "@kiwicom/orbit-components/icons"
import {
  Clock,
  Location as LocationIcon,
  RouteOneStop,
} from "@kiwicom/orbit-components/icons"
import mq from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { EmberCard, EmberCardGroup } from "components/generic/ember-card"
import { NavigationMenuLink } from "components/generic/navigation/nav-bar-link"

import {
  Location,
  Route,
  StopReplacementPattern,
  SuspensionPattern,
} from "types/location"

import { capitalizeFirstLetter } from "utils/string-utils"

import { formatActiveTimeRange } from "./utils"

export const DisruptionActions = ({
  refreshingDisruption,
  patterns,
  suspensionPatterns,
  allowAdminActions,
  locations,
  openStopReplacementPatternModal,
  setEditStopReplacementPattern,
  openSuspensionPatternModal,
  setEditSuspensionPattern,
}: {
  refreshingDisruption: boolean
  patterns: StopReplacementPattern[]
  suspensionPatterns: SuspensionPattern[]
  allowAdminActions: boolean
  locations: Location[]
  openStopReplacementPatternModal?: () => void
  setEditStopReplacementPattern: (
    pattern: StopReplacementPattern | null
  ) => void
  openSuspensionPatternModal?: () => void
  setEditSuspensionPattern: (pattern: SuspensionPattern | null) => void
}) => {
  const allPatterns = [...patterns, ...suspensionPatterns].sort(
    (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
  )
  const isLoading = refreshingDisruption || locations.length === 0

  return (
    <EmberCardGroup
      sectionTitle="Actions"
      actions={
        allowAdminActions && [
          <Popover
            noPadding
            content={
              <>
                <NavigationMenuLink
                  key="Replace Stop"
                  level="secondary"
                  $dark={false}
                  onClick={openStopReplacementPatternModal}
                >
                  Replace Stop
                </NavigationMenuLink>
                <NavigationMenuLink
                  key="Cancel Stop"
                  level="secondary"
                  $dark={false}
                  onClick={openSuspensionPatternModal}
                >
                  Cancel Stop
                </NavigationMenuLink>
              </>
            }
          >
            <Button type="secondary" iconLeft={<Plus />} loading={false} />
          </Popover>,
        ]
      }
    >
      {isLoading ? (
        <Loading type="pageLoader" text="Loading actions..." />
      ) : (
        <>
          {allPatterns.length === 0 && <Text>No actions taken.</Text>}
          <Stack direction="column" spacing="medium">
            {allPatterns.map((pattern) => {
              if ("suspendedLocationId" in pattern) {
                return (
                  <SuspensionPatternTile
                    key={pattern.uid}
                    pattern={pattern}
                    locations={locations}
                    setEditSuspensionPattern={setEditSuspensionPattern}
                  />
                )
              } else {
                return (
                  <StopReplacementPatternTile
                    key={pattern.uid}
                    pattern={pattern}
                    locations={locations}
                    setEditStopReplacementPattern={
                      setEditStopReplacementPattern
                    }
                  />
                )
              }
            })}
          </Stack>
        </>
      )}
    </EmberCardGroup>
  )
}

const HighlightedSpan = styled.span`
  font-weight: 500;
`

const TileContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    cursor: pointer;
    background: ${theme.orbit.paletteWhite};
    border: ${theme.orbit.borderWidthCard} ${theme.orbit.borderStyleCard}
      ${theme.orbit.borderColorCard};
    border-radius: ${theme.orbit.borderRadiusNormal};

    /* mobile screens */
    grid-template-columns: auto;
    row-gap: 1em;
    padding: ${theme.orbit.spaceMedium};

    /* desktop screens */
    ${mq.largeMobile(css`
      padding: ${theme.orbit.spaceLarge};
    `)}

    &:hover {
      background: ${theme.orbit.paletteWhiteHover};
    }
  `}
`

function getRoutesByNumber(routes: Route[]): Record<string, Route[]> {
  return routes.reduce(
    (acc, route) => ({
      ...acc,
      [route.number]: (acc[route.number] || []).concat(route),
    }),
    {}
  )
}

const StopReplacementPatternTile = ({
  pattern,
  locations,
  setEditStopReplacementPattern,
}: {
  pattern: StopReplacementPattern
  locations: Location[]
  setEditStopReplacementPattern: (
    pattern: StopReplacementPattern | null
  ) => void
}) => {
  const originalStop = locations.find(
    (location) => location.id === pattern.originalLocationId
  )
  const replacementStop = locations.find(
    (location) => location.id === pattern.replacementLocationId
  )

  return (
    <EmberCard>
      <TileContainer onClick={() => setEditStopReplacementPattern(pattern)}>
        <Stack spacing="medium">
          <Stack direction="row">
            <LocationIcon />
            <Text size="large">
              <HighlightedSpan>{getLocationName(originalStop)}</HighlightedSpan>{" "}
              replaced by{" "}
              <HighlightedSpan>
                {getLocationName(replacementStop)}
              </HighlightedSpan>
            </Text>
          </Stack>
          {pattern.routes && pattern.routes.length > 0 && (
            <RouteBadges routes={pattern.routes} />
          )}
          <Stack direction="row">
            <Clock />
            <Text>
              {formatActiveTimeRange({
                startTime: pattern.startTime,
                endTime: pattern.endTime,
                daysOfWeek: pattern.daysOfWeek,
                startTimeWithinDay: pattern.startTimeWithinDay,
                endTimeWithinDay: pattern.endTimeWithinDay,
              })}
            </Text>
          </Stack>
        </Stack>
      </TileContainer>
    </EmberCard>
  )
}

const SuspensionPatternTile = ({
  pattern,
  locations,
  setEditSuspensionPattern,
}: {
  pattern: SuspensionPattern
  locations: Location[]
  setEditSuspensionPattern: (pattern: SuspensionPattern | null) => void
}) => {
  const suspendedStop = locations.find(
    (location) => location.id === pattern.suspendedLocationId
  )

  return (
    <EmberCard>
      <TileContainer onClick={() => setEditSuspensionPattern(pattern)}>
        <Stack spacing="medium">
          <Stack direction="row">
            <LocationIcon />
            <Text size="large">
              <HighlightedSpan>
                {getLocationName(suspendedStop)}
              </HighlightedSpan>{" "}
              cancelled
            </Text>
          </Stack>
          {pattern.routes && pattern.routes.length > 0 && (
            <RouteBadges routes={pattern.routes} />
          )}
          <Stack direction="row">
            <Clock />
            <Text>
              {formatActiveTimeRange({
                startTime: pattern.startTime,
                endTime: pattern.endTime,
                daysOfWeek: pattern.daysOfWeek,
                startTimeWithinDay: pattern.startTimeWithinDay,
                endTimeWithinDay: pattern.endTimeWithinDay,
              })}
            </Text>
          </Stack>
        </Stack>
      </TileContainer>
    </EmberCard>
  )
}

function getLocationName(location: Location): string {
  return `${location.regionName} ${location.detailedName}`
}

const RouteBadges = ({ routes }: { routes: Route[] }) => {
  const routesByNumber = getRoutesByNumber(routes)
  return (
    <Stack direction="row">
      <RouteOneStop />
      {Object.keys(routesByNumber).map((routeNumber) => {
        const routes = routesByNumber[routeNumber]
        return (
          <Badge key={`${routeNumber}`} type="successSubtle">
            {routes.length === 1
              ? `${routeNumber} - ${
                  routes[0].description ||
                  capitalizeFirstLetter(routes[0].direction)
                }`
              : routeNumber}
          </Badge>
        )
      })}
    </Stack>
  )
}
