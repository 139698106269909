import React from "react"

import { Box, Checkbox, Stack, Text } from "@kiwicom/orbit-components"

import { EmberRadioAccordion } from "components/generic/formik-inputs"

import { Route } from "types/location"

import { capitalizeFirstLetter } from "utils/string-utils"

export const RouteSelector = ({
  allRoutes,
  selectedRoutes,
  setFieldValue,
}: {
  allRoutes: Route[]
  selectedRoutes: string[]
  setFieldValue: (field: string, value: string[]) => void
}): React.ReactElement => {
  return (
    <EmberRadioAccordion
      name="routeSelection"
      label="Routes"
      options={[
        { value: "all-routes", label: "All Routes Using This Stop" },
        {
          value: "specific-routes",
          label: "Specific Routes",
          selectedContent: (
            <Box largeMobile={{ padding: { left: "large" } }}>
              <Stack spacing="small">
                {allRoutes.map((route) => (
                  <Checkbox
                    key={route.id}
                    checked={selectedRoutes.includes(route.id.toString())}
                    onChange={(event) =>
                      setFieldValue(
                        "routes",
                        event.currentTarget.checked
                          ? [...selectedRoutes, route.id.toString()]
                          : selectedRoutes.filter(
                              (r) => r != route.id.toString()
                            )
                      )
                    }
                    label={
                      <Text>{`${route.number} - ${
                        route.description ||
                        capitalizeFirstLetter(route.direction)
                      }`}</Text>
                    }
                    dataTest={`checkbox-specific-route-${route.id}`}
                  />
                ))}
              </Stack>
            </Box>
          ),
        },
      ]}
    />
  )
}
