import { Disruption } from "./location"
import { Person } from "./person"

export enum SuspensionType {
  PAUSE_NEW_BOOKINGS = "pause_new_bookings",
  PUBLIC_CANCELLATION = "public_cancellation",
}

export enum DisruptionReason {
  // This enum should be kept in sync with its backend relative
  // See `ember_api/service_definition/timetable/models.py`
  //
  // Also, when adding values, remember to also add those values to the
  // functions below that generate user-facing text.

  ROAD_CLOSURE = "road_closure",
  BRIDGE_CLOSURE = "bridge_closure",
  SNOW = "snow",
  HIGH_WINDS = "high_winds",
  FLOODING = "flooding",
  OTHER_WEATHER = "other_weather",
  DUPLICATE_NO_LONGER_NEEDED = "duplicate_no_longer_needed",
  VEHICLE_FAULT = "vehicle_fault",
  DRIVER_ISSUE = "driver_issue",
  OTHER = "other",
}

/**
 * Returns a string that describes the given reason and fits in the sentence
 * "the bus was cancelled due to ___"
 */
export function suspensionDueToPublicDisplayText(
  reason: DisruptionReason
): string {
  const dueTo = {
    [DisruptionReason.ROAD_CLOSURE]: "a road closure",
    [DisruptionReason.BRIDGE_CLOSURE]: "a bridge closure",
    [DisruptionReason.SNOW]: "snow",
    [DisruptionReason.HIGH_WINDS]: "high winds",
    [DisruptionReason.FLOODING]: "flooding",
    [DisruptionReason.OTHER_WEATHER]: "the weather",
    [DisruptionReason.VEHICLE_FAULT]: "a vehicle fault",
    [DisruptionReason.DRIVER_ISSUE]: "a driver issue",
    [DisruptionReason.OTHER]: "an operational issue",
    // if adding strings here also add them to `DisruptionReason.as_public_str`
    // in `ember_api/service_definition/timetable/models.py`
  }[reason ?? DisruptionReason.OTHER]
  // Falling back to this generic message would be a bug, e.g. if a new value
  // was added to the enum but not in this function
  return dueTo || "an operational issue"
}

export interface Suspension {
  uid: string
  type: SuspensionType
  reason: DisruptionReason

  // These fields are only present if the `Suspension` object was returned by
  // an endpoint that had been called by an admin. Customers don't get to see
  // these.
  createdAt?: string
  modifiedAt?: string
  privateNotes?: string
  initiator?: Person

  // This field is not returned by the API, we construct it when reshaping the
  // data
  locationTimeIds?: number[]

  // Some Suspensions are applied via a cancellation pattern.
  disruption?: Disruption
}
