// @ts-strict-ignore
import React, { useEffect } from "react"

import { EmptyStateCardSection } from "components/generic/empty-state-card-section"
import { ColumnContext } from "components/generic/multi-column-layout"

import { Disruption } from "types/location"

import { DisruptionTile } from "./disruption-tile"

interface DisruptionSidebarListProps {
  disruptions: Disruption[]
  selectedDisruptionId: string
  handleSelectDisruption: (disruptionId: string) => void
}

const DisruptionSidebarList = ({
  disruptions,
  selectedDisruptionId,
  handleSelectDisruption,
}: DisruptionSidebarListProps) => {
  const context = React.useContext(ColumnContext)
  useEffect(() => {
    const tripIds = disruptions.map((disruption) => disruption.uid)
    if (
      disruptions.length > 0 &&
      (selectedDisruptionId == null ||
        !tripIds.includes(selectedDisruptionId)) &&
      context.numberColumns > 1
    ) {
      handleSelectDisruption(disruptions[0].uid)
    }
  }, [JSON.stringify(disruptions), context.numberColumns])

  return (
    <>
      {disruptions.length < 1 ? (
        <EmptyStateCardSection
          title="No Disruptions Found"
          description="Try changing the filter"
          omitIllustration
        />
      ) : (
        disruptions.map((disruption) => (
          <DisruptionTile
            key={disruption.uid}
            disruption={disruption}
            selected={disruption.uid == selectedDisruptionId}
            handleSelectDisruption={handleSelectDisruption}
          />
        ))
      )}
    </>
  )
}

export { DisruptionSidebarList }
