// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Alert, Button, Loading, Stack } from "@kiwicom/orbit-components"
import { ChevronBackward } from "@kiwicom/orbit-components/icons"

import { fetchDisruption } from "api/disruptions"
import { EmberApiError } from "api/errors"

import {
  Disruption,
  StopReplacementPattern,
  SuspensionPattern,
} from "types/location"
import { Location } from "types/location"
import { LocationType } from "types/location"
import { Unsubscribable } from "types/observable"

import { fetchLocationOptions } from "utils/location-utils"
import { useGlobalFetcher } from "utils/state-utils"

import { DisruptionInfo } from "./disruption-info"
import DisruptionModal from "./disruption-modal"
import StopReplacementPatternModal from "./modals/stop-replacement-pattern-modal"
import SuspensionPatternModal from "./modals/suspension-pattern-modal"

type DisruptionDetailsProps = {
  disruptionUid?: string
  fullRefreshCount?: number
  triggerFullRefresh?: () => void
  handleReturnToAllDisruptions?: () => void
  numberColumns?: number
  allowAdminActions: boolean
  updateOneDisruption?: (disruption: Disruption) => void
  locations: Location[]
}

const DisruptionDetails = ({
  disruptionUid,
  fullRefreshCount,
  triggerFullRefresh,
  handleReturnToAllDisruptions,
  numberColumns,
  allowAdminActions,
  updateOneDisruption,
  locations,
}: DisruptionDetailsProps) => {
  const [loadingDisruption, setLoadingDisruption] = useState(false)
  const [refreshingDisruption, setRefreshingDisruption] = useState(false)
  const [loadingDisruptionError, setLoadingDisruptionError] =
    useState<EmberApiError>(null)
  const [disruptionSubscription, setDisruptionSubscription] =
    useState<Unsubscribable>()

  const [showStopReplacementPatternModal, setShowStopReplacementPatternModal] =
    useState(false)
  const [showSuspensionPatternModal, setShowSuspensionPatternModal] =
    useState(false)
  const [showEditDisruptionModal, setShowEditDisruptionModal] = useState(false)
  const [editStopReplacementPattern, setEditStopReplacementPattern] =
    useState<StopReplacementPattern | null>(null)
  const [editSuspensionPattern, setEditSuspensionPattern] =
    useState<SuspensionPattern | null>(null)

  const { data: routes } = useGlobalFetcher("routes")
  const activeRoutes = routes?.filter(
    (route) => !route.inactiveFrom || new Date(route.inactiveFrom) > new Date()
  )

  const locationOptions = fetchLocationOptions([LocationType.STOP_POINT])

  const [disruption, setDisruption] = useState<Disruption>(null)

  async function partialRefresh() {
    setRefreshingDisruption(true)
    await loadDisruptionData()
    setRefreshingDisruption(false)
  }

  useEffect(() => {
    fullRefresh()
  }, [disruptionUid, fullRefreshCount])

  async function fullRefresh() {
    if (disruptionUid != null) {
      setLoadingDisruption(true)
      setLoadingDisruptionError(null)
      setDisruption(null)
      await loadDisruptionData()
      setLoadingDisruption(false)
    }
  }

  function loadDisruptionData() {
    return new Promise<void>((resolve) => {
      disruptionSubscription?.unsubscribe()
      const sub = fetchDisruption({
        disruptionUid: disruptionUid,
        onSuccess: (disruption: Disruption) => {
          setDisruption(disruption)
          if (updateOneDisruption) {
            updateOneDisruption(disruption)
          }
        },
        onError: setLoadingDisruptionError,
        onCompletion: resolve,
      })
      setDisruptionSubscription(sub)
    })
  }

  const openStopReplacementPatternModal = () => {
    setShowStopReplacementPatternModal(true)
  }

  const openSuspensionPatternModal = () => {
    setShowSuspensionPatternModal(true)
  }

  return (
    <>
      <Stack spacing="large">
        {numberColumns == 1 && handleReturnToAllDisruptions && (
          <Stack direction="row" justify="between">
            <Button
              type="secondary"
              iconLeft={<ChevronBackward />}
              onClick={() => handleReturnToAllDisruptions()}
            >
              All disruptions
            </Button>
          </Stack>
        )}
        {!disruptionUid && !loadingDisruption ? (
          <Alert type="info">No disruption selected</Alert>
        ) : loadingDisruption ? (
          <Loading type="pageLoader" text="Loading disruption details..." />
        ) : loadingDisruptionError ? (
          <Alert type="critical" title="Error loading the disruption details">
            The error message was "{loadingDisruptionError.message}"
          </Alert>
        ) : (
          disruption != null && (
            <DisruptionInfo
              disruption={disruption}
              allowAdminActions={allowAdminActions}
              locations={locations}
              setShowEditDisruptionModal={setShowEditDisruptionModal}
              openStopReplacementPatternModal={() => {
                setEditStopReplacementPattern(null)
                openStopReplacementPatternModal()
              }}
              setEditStopReplacementPattern={(pattern) => {
                setEditStopReplacementPattern(pattern)
                setShowStopReplacementPatternModal(true)
              }}
              openSuspensionPatternModal={() => {
                setEditSuspensionPattern(null)
                openSuspensionPatternModal()
              }}
              setEditSuspensionPattern={(pattern) => {
                setEditSuspensionPattern(pattern)
                setShowSuspensionPatternModal(true)
              }}
              refreshingDisruption={refreshingDisruption}
            />
          )
        )}
      </Stack>
      {showStopReplacementPatternModal && (
        <StopReplacementPatternModal
          disruptionUid={disruption?.uid}
          pattern={editStopReplacementPattern}
          handleClose={() => setShowStopReplacementPatternModal(false)}
          handleUpdate={() => {
            partialRefresh()
            setShowStopReplacementPatternModal(false)
          }}
          locationOptions={locationOptions}
          routes={activeRoutes}
        />
      )}
      {showSuspensionPatternModal && (
        <SuspensionPatternModal
          disruptionUid={disruption?.uid}
          pattern={editSuspensionPattern}
          handleClose={() => setShowSuspensionPatternModal(false)}
          handleUpdate={() => {
            partialRefresh()
            setShowSuspensionPatternModal(false)
          }}
          locationOptions={locationOptions}
          routes={activeRoutes}
        />
      )}
      {showEditDisruptionModal && (
        <DisruptionModal
          disruption={disruption}
          handleClose={() => setShowEditDisruptionModal(false)}
          handleUpdate={() => {
            triggerFullRefresh()
            setShowEditDisruptionModal(false)
          }}
        />
      )}
    </>
  )
}

export default DisruptionDetails
