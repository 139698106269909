// @ts-strict-ignore
import {
  formatAsAbbreviatedDateTime,
  formatAsTime,
  getDefaultTimezone,
} from "utils/date-utils"
import { capitalizeFirstLetter } from "utils/string-utils"

import { DAYS_OF_WEEK } from "./types"

const displayDaysOfWeek = (days: number[]): string => {
  if (days.length === 7) {
    return "every day"
  }

  // ISO weekday indexing starts from 1 instead of 0.
  const shiftedDays = days.map((day) => day - 1)

  // Combine consequent days, eg. Mon, Tue, Wed -> Mon-Wed.
  const combinedDays = []
  let cumulativeDays = []

  function unrollCumulativeDays() {
    if (cumulativeDays.length === 1) {
      combinedDays.push(DAYS_OF_WEEK[cumulativeDays[0]])
    } else {
      combinedDays.push(
        `${DAYS_OF_WEEK[cumulativeDays[0]].slice(0, 3)}-${DAYS_OF_WEEK[
          cumulativeDays[cumulativeDays.length - 1]
        ].slice(0, 3)}`
      )
    }
    cumulativeDays = []
  }

  for (let i = 0; i < shiftedDays.length; i++) {
    if (cumulativeDays.length === 0) {
      cumulativeDays.push(shiftedDays[i])
    } else if (
      shiftedDays[i] ===
      cumulativeDays[cumulativeDays.length - 1] + 1
    ) {
      cumulativeDays.push(shiftedDays[i])
    } else {
      unrollCumulativeDays()
      cumulativeDays.push(shiftedDays[i])
    }
  }

  unrollCumulativeDays()

  if (combinedDays.length === 1) {
    return combinedDays[0]
  }
  return `${combinedDays.slice(0, combinedDays.length - 1).join(", ")} and ${
    combinedDays[combinedDays.length - 1]
  }`
}

const formatTimeWithoutSeconds = (time: string) => {
  return time.slice(0, 5) // Extracts the HH:MM part of the time string
}

export const formatActiveTimeRange = ({
  startTime,
  endTime,
  startTimeWithinDay,
  endTimeWithinDay,
  daysOfWeek,
}: {
  startTime: string
  endTime?: string
  startTimeWithinDay?: string
  endTimeWithinDay?: string
  daysOfWeek?: number[]
}): string => {
  const timezone = getDefaultTimezone()
  const startDate = new Date(startTime)
  const endDate = endTime ? new Date(endTime) : null

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  // Eg. "from Friday 12 July 2024 to Friday 19 July"
  let activeTimeRangeDisplay = `from ${formatAsAbbreviatedDateTime(
    startTime,
    timezone
  )}`

  // Eg. "12:00 - 19:00"
  let timeWithinDayDisplay = ""
  if (startTimeWithinDay && endTimeWithinDay) {
    timeWithinDayDisplay = `${formatTimeWithoutSeconds(
      startTimeWithinDay
    )} - ${formatTimeWithoutSeconds(endTimeWithinDay)}`
  }

  // Eg. "on Mon-Wed and Friday"
  let daysOfWeekDisplay = ""
  if (daysOfWeek && daysOfWeek.length > 0) {
    if (timeWithinDayDisplay) {
      daysOfWeekDisplay = `on ${displayDaysOfWeek(daysOfWeek)}`
    } else {
      daysOfWeekDisplay = `${displayDaysOfWeek(daysOfWeek)}`
    }
  }

  // Eg. "from Tuesday 16 July 2024 to Wednesday 17 July 2024"
  if (endTime) {
    activeTimeRangeDisplay = `${formatAsAbbreviatedDateTime(
      startDate,
      timezone
    )} to ${formatAsAbbreviatedDateTime(endDate, timezone)}`

    if (startTimeWithinDay || endTimeWithinDay || daysOfWeek) {
      activeTimeRangeDisplay = `from ${activeTimeRangeDisplay}`
    }
  } else {
    if (startDate.toDateString() === today.toDateString()) {
      activeTimeRangeDisplay = `from ${formatAsTime(startTime, timezone)} today`
    } else if (startDate.toDateString() === tomorrow.toDateString()) {
      activeTimeRangeDisplay = `from ${formatAsTime(
        startTime,
        timezone
      )} tomorrow`
    }
  }

  // Remove year if it's the current year.
  if (startDate.getFullYear() === today.getFullYear() && !endTime) {
    activeTimeRangeDisplay = activeTimeRangeDisplay.replace(
      ` ${startDate.getFullYear()}`,
      ""
    )
  }

  if (timeWithinDayDisplay) {
    return capitalizeFirstLetter(
      `${timeWithinDayDisplay} ${daysOfWeekDisplay} ${activeTimeRangeDisplay}`.trim()
    )
  }
  return capitalizeFirstLetter(
    `${daysOfWeekDisplay} ${activeTimeRangeDisplay}`.trim()
  )
}
