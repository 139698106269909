// @ts-strict-ignore
import React, { useEffect, useRef, useState } from "react"

import { format, isBefore, parseISO } from "date-fns"
import { usePopper } from "react-popper"

import {
  Box,
  ButtonLink,
  InputField,
  useLockScrolling,
  useMediaQuery,
} from "@kiwicom/orbit-components"
import { ChevronDown, Close } from "@kiwicom/orbit-components/lib/icons"

import type { Location } from "types/location"

import {
  BookableFrom,
  DetailedName,
  LocationIconGrid,
  LocationItem,
  LocationsListPopover,
  LocationsListPopoverHeader,
  LocationsListPopoverListHeader,
  NoDiscUnorderedList,
  RegionName,
} from "./location-picker.styled"

type LocationPickerProps = {
  label: string
  defaultValue: string
  locations: Location[]
  recentLocations?: Location[]
  onChange?: (location: Location) => void
  /**
   *  A slight hack to get the internal header to show "Available Stops from X"
   *  the alterative was to pass in a prop which would decide which header to show
   *  but this would require the filtering function to be lifted to the parent component
   *  which would be a bit messy.
   */
  availableFrom?: string
  inputProps?: React.ComponentProps<typeof InputField>
  header?: string
}

type LocationWithSearchScore = Location & {
  score: number
}

export const LocationPicker = ({
  label,
  defaultValue,
  locations,
  recentLocations,
  onChange,
  availableFrom,
  inputProps,
  header,
}: LocationPickerProps) => {
  const { isLargeMobile } = useMediaQuery()

  const [value, setValue] = useState<string>("")
  const [placeholder, setPlaceholder] = useState("")

  const [focused, setFocused] = useState<number | null>(null)

  const outerInput = useRef<HTMLInputElement>()
  const innerInput = useRef<HTMLInputElement>()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes, forceUpdate } = usePopper(
    outerInput.current,
    popperElement,
    {
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top-start"],
          },
        },
      ],
    }
  )

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    outerInput.current.spellcheck = false
  }, [])

  const hasWindow = typeof window !== "undefined"
  const [popoverHeight, setPopoverHeight] = useState(null)
  useEffect(() => {
    if (!window.visualViewport) {
      return
    }
    setPopoverHeight(window.visualViewport.height)
    if (hasWindow) {
      // eslint-disable-next-line no-inner-declarations
      function handleResize() {
        setPopoverHeight(window.visualViewport.height)
      }

      window.visualViewport.addEventListener("resize", handleResize)
      return () =>
        window.visualViewport.removeEventListener("resize", handleResize)
    }
  }, [hasWindow])

  const onFocus = (_: React.FocusEvent<HTMLInputElement, Element>): void => {
    if (forceUpdate) {
      forceUpdate()
    }
    setPopoverOpen(true)
    setPlaceholder(value ?? defaultValue)
    setValue("")

    if (!isLargeMobile) {
      setTimeout(() => {
        if (!innerInput.current) {
          return
        }
        innerInput.current.value = ""
        innerInput.current.placeholder = value ?? defaultValue
        innerInput.current.focus({ preventScroll: true })
        if (forceUpdate) {
          forceUpdate()
        }
      }, 0)
    }
  }

  const onBlur = (_: React.FocusEvent<HTMLInputElement, Element>): void => {
    setFocused(null)
    if (isLargeMobile) {
      setPopoverOpen(false)
    }

    resetInputValue()
  }

  const resetInputValue = (): void => {
    setPlaceholder("")

    const hasSelectedLocation = locations.some(
      (location) => location.name === value
    )

    if (value === "" || !hasSelectedLocation) {
      setValue(defaultValue)
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFocused(0)
    setValue(event.target.value)
    if (scrollWrapperRef.current) {
      scrollWrapperRef.current.scrollTop = 0
    }
  }

  const onLocationSelected = (location: Location) => {
    if (!location) {
      return
    }
    setValue(location.name)
    setPopoverOpen(false)
    onChange(location)
    outerInput.current?.blur()
  }

  const scrollWrapperRef = useRef<HTMLUListElement | null>(null)
  useLockScrolling(scrollWrapperRef, popoverOpen && !isLargeMobile)

  const filterLocationsOnTypedValue = (location: Location): boolean =>
    location.name.toLowerCase().includes(value.toLowerCase()) ||
    location.detailedName.toLowerCase().includes(value.toLowerCase()) ||
    location.regionName.toLowerCase().includes(value.toLowerCase())

  const popoverListHeader = (): string => {
    if (header) {
      return header
    }
    if (value === "") {
      return availableFrom != null
        ? `All Stops from ${availableFrom}`
        : "All Stops"
    } else if (locations.filter(filterLocationsOnTypedValue).length === 0) {
      return "No Available Stops"
    } else {
      return `Available Stops ${
        availableFrom != null ? `from ${availableFrom}` : ""
      }`
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "ArrowDown" && focused == null) {
      e.preventDefault()
      setFocused(0)
    } else if (e.key === "ArrowDown" && focused != null) {
      e.preventDefault()
      setFocused(() =>
        focused + 1 >=
        filteredAndSortedLocations?.length + recentLocations?.length
          ? focused
          : focused + 1
      )
    } else if (e.key === "ArrowUp" && focused != null) {
      e.preventDefault()
      setFocused(() => (focused - 1 < 0 ? 0 : focused - 1))
    } else if (e.key === "Enter" && focused != null) {
      e.preventDefault()
      const combinedLocations = [
        ...(value === "" ? recentLocations ?? [] : []),
        ...filteredAndSortedLocations,
      ]
      onLocationSelected(combinedLocations[focused])
    } else if (e.key === "Escape") {
      outerInput.current?.blur()
      setPopoverOpen(false)
    }
  }

  const filteredAndSortedLocations = locations
    .filter(filterLocationsOnTypedValue)
    .map((location): LocationWithSearchScore => {
      const score = location.regionName
        .toLowerCase()
        .includes(value.toLowerCase())
        ? 1.2
        : 1

      return { ...location, score: score }
    })
    .sort((a, b) => b.score - a.score)

  return (
    <>
      {popoverOpen && (
        <LocationsListPopover
          onMouseDown={(e) => e.preventDefault()} // This prevents `onBlur` firing in the input field, which closes the popover
          isFullscreen={!isLargeMobile}
          viewportHeight={popoverHeight}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {!isLargeMobile && (
            <>
              <LocationsListPopoverHeader>
                {label}{" "}
                <ButtonLink
                  onClick={() => {
                    setPopoverOpen(false)
                    resetInputValue()
                  }}
                  size="normal"
                  iconLeft={<Close />}
                  dataTest={`location-picker-${label}-popover-close-button`}
                  type="secondary"
                  title={"Close"}
                />
              </LocationsListPopoverHeader>
              <Box padding={"medium"}>
                <InputField
                  ref={innerInput}
                  type="text"
                  autoComplete="off"
                  value={value ?? defaultValue}
                  placeholder={placeholder}
                  onChange={onInputChange}
                  onFocus={(_) => {
                    setPlaceholder(value)
                    setValue("")
                  }}
                  dataTest={`location-picker-${label}-popover-input`}
                  id={`location-picker-${label}-popover-input`}
                />
              </Box>
            </>
          )}
          <NoDiscUnorderedList
            ref={scrollWrapperRef}
            data-test={`location-picker-list-${label}`}
          >
            {recentLocations != null &&
              recentLocations.length > 0 &&
              value === "" && (
                <>
                  <LocationsListPopoverListHeader>
                    Recent Stops
                  </LocationsListPopoverListHeader>
                  {recentLocations.map((location, index) => (
                    <ListItem
                      key={`recent-${location.id}`}
                      data-test={"location-picker-recent-item-" + location.id}
                      focused={focused === index}
                      location={location}
                      onSelected={onLocationSelected}
                    >
                      <LocationIconGrid />
                      <RegionName>{location.regionName}</RegionName>
                      <DetailedName>{location.detailedName}</DetailedName>
                      {isBefore(
                        new Date(),
                        new Date(location.bookableFrom)
                      ) && (
                        <BookableFrom
                          data-test={`recent-bookable-from-${location.id}`}
                        >
                          From{" "}
                          {format(parseISO(location.bookableFrom), "d MMM")}
                        </BookableFrom>
                      )}
                    </ListItem>
                  ))}
                </>
              )}
            <LocationsListPopoverListHeader>
              {popoverListHeader()}
            </LocationsListPopoverListHeader>

            {filteredAndSortedLocations.map((location, index) => (
              <ListItem
                key={location.id}
                focused={
                  focused ===
                  index + (value.length > 0 ? 0 : recentLocations?.length ?? 0)
                }
                location={location}
                onSelected={onLocationSelected}
              >
                <LocationIconGrid />
                <RegionName>{location.regionName}</RegionName>
                <DetailedName>{location.detailedName}</DetailedName>
                {isBefore(new Date(), new Date(location.bookableFrom)) && (
                  <BookableFrom data-test={`bookable-from-${location.id}`}>
                    From {format(parseISO(location.bookableFrom), "d MMM")}
                  </BookableFrom>
                )}
              </ListItem>
            ))}
          </NoDiscUnorderedList>
        </LocationsListPopover>
      )}

      <InputField
        ref={outerInput}
        type="text"
        autoComplete="off"
        value={value ?? defaultValue}
        placeholder={placeholder}
        dataTest={`location-picker-${label}-input`}
        id={`location-picker-${label}-input`}
        readOnly={!isLargeMobile}
        label={label}
        suffix={<Box padding={{ right: "XSmall" }}>{<ChevronDown />}</Box>}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onInputChange}
        role="combobox"
        ariaControls={`location-picker-popover-${label}`}
        ariaHasPopup={true}
        ariaExpanded={popoverOpen}
        onKeyDown={onKeyDown}
        {...inputProps}
      />
    </>
  )
}

export default LocationPicker

type ListItemProps = React.PropsWithChildren<{
  location: Location
  focused: boolean
  onSelected: (location: Location) => void
}>

const ListItem = ({
  location,
  focused,
  onSelected,
  children,
}: ListItemProps) => {
  const ref = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (focused) {
      ref.current?.scrollIntoView({ block: "nearest" })
    }
  }, [focused])

  return (
    <LocationItem
      ref={ref}
      data-test={"location-picker-item-" + location.id}
      onMouseDown={(e) => {
        e.preventDefault() // This prevents `onBlur` firing in the input field, which stops the `onClick` below
      }}
      onClick={() => {
        onSelected(location)
      }}
      focused={focused}
    >
      {children}
    </LocationItem>
  )
}
