import { EmberApiError, parseError } from "api/errors"

import {
  Disruption,
  StopReplacementPattern,
  SuspensionPattern,
} from "types/location"
import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { camelizeKeysExceptUids } from "utils/string-utils"

interface FetchDisruptionParams {
  disruptionUid: string
  onSuccess: (closure: Disruption) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchDisruption({
  disruptionUid,
  onSuccess,
  onError,
  onCompletion = () => null,
}: FetchDisruptionParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/${disruptionUid}/`,
    method: "GET",
  }).subscribe((response) => {
    response = camelizeKeysExceptUids(response)
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

interface FetchDisruptionsRequest {
  filter: "current" | "upcoming" | "past" | "all"
}

interface FetchDisruptionsParams {
  request: FetchDisruptionsRequest
  onSuccess: (disruptions: Disruption[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchDisruptions({
  request,
  onSuccess,
  onError,
}: FetchDisruptionsParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/${getQueryString(request)}`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface CreateDisruptionParams {
  disruption: Partial<Disruption>
  onSuccess: (closure: Disruption) => void
  onError: (error: EmberApiError) => void
}

export function createDisruption({
  disruption,
  onSuccess,
  onError,
}: CreateDisruptionParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/disruptions/",
    method: "POST",
    body: disruption,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface UpdateDisruptionParams {
  disruptionUid: string
  disruption: Partial<Disruption>
  onSuccess: (disruption: Disruption) => void
  onError: (error: EmberApiError) => void
}

export function updateDisruption({
  disruptionUid,
  disruption,
  onSuccess,
  onError,
}: UpdateDisruptionParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/${disruptionUid}/`,
    method: "PUT",
    body: disruption,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

type StopReplacementPatternPayload = Omit<StopReplacementPattern, "routes"> & {
  routes: number[]
}

interface CreateStopReplacementPatternParams {
  disruptionUid: string
  pattern: Partial<StopReplacementPatternPayload>
  onSuccess: (pattern: StopReplacementPattern) => void
  onError: (error: EmberApiError) => void
}

export function createStopReplacementPattern({
  disruptionUid,
  pattern,
  onSuccess,
  onError,
}: CreateStopReplacementPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/${disruptionUid}/stop-replacement-patterns/`,
    method: "POST",
    body: pattern,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface UpdateStopReplacementPatternParams {
  patternUid: string
  pattern: Partial<StopReplacementPatternPayload>
  onSuccess: (pattern: StopReplacementPattern) => void
  onError: (error: EmberApiError) => void
}

export function updateStopReplacementPattern({
  patternUid,
  pattern,
  onSuccess,
  onError,
}: UpdateStopReplacementPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/stop-replacement-patterns/${patternUid}/`,
    method: "PUT",
    body: pattern,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface DeleteDisruptionParams {
  disruptionUid: string
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}

export function deleteDisruption({
  disruptionUid,
  onSuccess,
  onError,
}: DeleteDisruptionParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/${disruptionUid}/`,
    method: "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}

interface DeleteStopReplacementPatternParams {
  patternUid: string
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}

export function deleteStopReplacementPattern({
  patternUid,
  onSuccess,
  onError,
}: DeleteStopReplacementPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/stop-replacement-patterns/${patternUid}/`,
    method: "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}

type SuspensionPatternPayload = Omit<SuspensionPattern, "routes"> & {
  routes: number[]
}

interface CreateSuspensionPatternParams {
  disruptionUid: string
  pattern: Partial<SuspensionPatternPayload>
  onSuccess: (pattern: SuspensionPattern) => void
  onError: (error: EmberApiError) => void
}

export function createSuspensionPattern({
  disruptionUid,
  pattern,
  onSuccess,
  onError,
}: CreateSuspensionPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/${disruptionUid}/suspension-patterns/`,
    method: "POST",
    body: pattern,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface UpdateSuspensionPatternParams {
  patternUid: string
  pattern: Partial<SuspensionPatternPayload>
  onSuccess: (pattern: SuspensionPattern) => void
  onError: (error: EmberApiError) => void
}

export function updateSuspensionPattern({
  patternUid,
  pattern,
  onSuccess,
  onError,
}: UpdateSuspensionPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/suspension-patterns/${patternUid}/`,
    method: "PUT",
    body: pattern,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface DeleteSuspensionPatternParams {
  patternUid: string
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}

export function deleteSuspensionPattern({
  patternUid,
  onSuccess,
  onError,
}: DeleteSuspensionPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/disruptions/suspension-patterns/${patternUid}/`,
    method: "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}
