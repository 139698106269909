import React from "react"

import {
  Badge,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  Truncate,
} from "@kiwicom/orbit-components"
import { CloseCircle, Replace } from "@kiwicom/orbit-components/icons"

import { ListTile } from "components/generic/multi-column-layout"

import { Disruption } from "types/location"

import { formatAsMinimizedDate, getDefaultTimezone } from "utils/date-utils"

interface DisruptionTileProps {
  disruption: Disruption
  selected: boolean
  handleSelectDisruption: (disruptionId) => void
}

const DisruptionBadge = ({
  startTime,
  endTime,
}: {
  startTime: Date
  endTime: Date | null
}) => {
  const now = new Date()
  let text, type

  if (now < startTime) {
    text = "Upcoming"
    type = "infoSubtle"
  } else if (endTime != null && now > endTime) {
    text = "Past"
    type = "criticalSubtle"
  } else {
    text = "Current"
    type = "successSubtle"
  }

  return <Badge type={type}>{text}</Badge>
}

const DisruptionTile = ({
  disruption,
  selected,
  handleSelectDisruption,
}: DisruptionTileProps) => {
  return (
    <ListTile
      dataTest="admin-disruption-item"
      selectable
      selected={selected}
      onClick={(event) => {
        if (event.defaultPrevented) return
        handleSelectDisruption(disruption.uid)
      }}
    >
      <Stack spacing="small">
        <Stack direction="row" justify="between" grow spacing="XSmall">
          <Truncate>
            <Heading type="title3">{disruption.title}</Heading>
          </Truncate>
          <DisruptionBadge
            startTime={new Date(disruption.startTime)}
            endTime={disruption.endTime ? new Date(disruption.endTime) : null}
          />
        </Stack>
        <Stack direction="row" align="end">
          <Stack spacing="small">
            <Text type="secondary" weight="medium">
              {formatAsMinimizedDate(
                disruption.startTime,
                getDefaultTimezone()
              )}
              {disruption.endTime && (
                <>
                  {" "}
                  -{" "}
                  {formatAsMinimizedDate(
                    disruption.endTime,
                    getDefaultTimezone()
                  )}
                </>
              )}
            </Text>
            <List size="small">
              <Stack direction="row" shrink>
                {disruption.patterns && (
                  <ListItem icon={<Replace />}>
                    {disruption.patterns.length} stop
                    {disruption.patterns.length !== 1 ? "s" : ""} replaced
                  </ListItem>
                )}
                {disruption.suspensionPatterns && (
                  <ListItem icon={<CloseCircle />}>
                    {disruption.suspensionPatterns.length} stop
                    {disruption.suspensionPatterns.length !== 1 ? "s" : ""}{" "}
                    cancelled
                  </ListItem>
                )}
              </Stack>
            </List>
          </Stack>
        </Stack>
      </Stack>
    </ListTile>
  )
}

export { DisruptionTile }
