import styled, { css } from "styled-components"

import { mediaQueries } from "@kiwicom/orbit-components"
import { Location as LocationIcon } from "@kiwicom/orbit-components/lib/icons"

export const LocationsListPopover = styled.div<{
  isFullscreen: boolean
  viewportHeight: number | null
}>`
    background-color: ${(props) => props.theme.orbit.paletteWhite};
    z-index 10000;
    display: flex;
    flex-direction: column;

    ${(props) =>
      props.isFullscreen &&
      `transform: translate3d(0px, 0px, 0px) !important; 
      inset: 0px 0px 0px 0px !important;`}
    
    height: ${(props) =>
      props.viewportHeight != null
        ? `${props.viewportHeight}px`
        : "100%"} !important;
    width: 100%;
    

    ${mediaQueries.largeMobile(css`
      max-height: 450px !important;
      height: fit-content !important;
      min-width: 320px;
      width: max-content;
      inset: inherit;
      transform: none;
      box-shadow: ${(props) => props.theme.orbit.boxShadowRaised};
    `)}
  `
export const LocationsListPopoverHeader = styled.div`
  background-color: ${(props) => props.theme.ember.brandColor};
  color: #ffffff;
  font-size: ${(props) => props.theme.orbit.fontSizeTextSmall};
  padding-left: ${(props) => props.theme.orbit.spaceSmall};
  min-height: ${(props) => props.theme.orbit.heightButtonNormal};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    color: #ffffff !important;
  }

  & button:hover {
    background-color: ${(props) => props.theme.orbit.paletteProductNormalHover};
  }
`

export const LocationsListPopoverListHeader = styled.div`
  background-color: ${(props) => props.theme.orbit.paletteCloudLight};
  color: ${(props) => props.theme.orbit.paletteInkNormal};
  font-size: ${(props) => props.theme.orbit.fontSizeTextSmall};
  font-weight: ${(props) => props.theme.orbit.fontWeightBold};
  padding: 0 ${(props) => props.theme.orbit.spaceSmall};
  min-height: ${(props) => props.theme.orbit.heightButtonNormal};
  border-bottom: 1px solid ${(props) => props.theme.orbit.paletteCloudNormal};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NoDiscUnorderedList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  flex-basis: max-content;
  box-sizing: content-box;

  ${mediaQueries.largeMobile(css`
    max-height: 450px !important;
    height: fit-content !important;
    overflow-y: auto;
  `)}
`
export const LocationItem = styled.li<{ focused: boolean }>`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  cursor: pointer;
  padding: ${(props) => props.theme.orbit.spaceSmall};
  border-bottom: 1px solid ${(props) => props.theme.orbit.paletteCloudNormal};
  width: 100%;

  ${(props) =>
    props.focused &&
    `background-color: ${props.theme.orbit.paletteCloudLight}`};

  &:hover, &:active {
    background-color: ${(props) => props.theme.orbit.paletteCloudLight}};
  }
`
export const LocationIconGrid = styled(LocationIcon)`
  grid-area: 1 / 1 / 4 / 2;
  margin: auto;
  color: ${(props) => props.theme.orbit.paletteInkNormal};
`
export const RegionName = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  color: ${(props) => props.theme.orbit.paletteInkNormal};
`
export const DetailedName = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  font-size: ${(props) => props.theme.orbit.fontSizeTextSmall};
  color: ${(props) => props.theme.orbit.paletteInkNormal};
`
export const BookableFrom = styled.div`
  grid-area: 3 / 2 / 4 / 3;
  font-size: ${(props) => props.theme.orbit.fontSizeTextSmall};
  color: ${(props) => props.theme.orbit.paletteInkNormal};
`
