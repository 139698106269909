import React from "react"

import Disruptions from "components/disruptions/disruptions"
import { AdminLayout } from "components/layout-custom"

const Page = () => {
  return (
    <AdminLayout title="Disruptions" padded={false}>
      <Disruptions />
    </AdminLayout>
  )
}

export default Page
