import { DisruptionReason } from "types/suspension"

export const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

const EXCLUDED_REASONS: DisruptionReason[] = [
  DisruptionReason.DUPLICATE_NO_LONGER_NEEDED,
  DisruptionReason.VEHICLE_FAULT,
  DisruptionReason.DRIVER_ISSUE,
]

export const filteredDisruptionReasons: DisruptionReason[] = Object.values(
  Object.fromEntries(
    Object.entries(DisruptionReason).filter(
      ([_, value]) => !EXCLUDED_REASONS.includes(value as DisruptionReason)
    )
  )
)
export type FilteredDisruptionReason = keyof typeof filteredDisruptionReasons
