// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import {
  Alert,
  AlertButton,
  Button,
  InputField,
  Loading,
  Stack,
  Text,
} from "@kiwicom/orbit-components"
import { Plus } from "@kiwicom/orbit-components/icons"

import { fetchDisruptions } from "api/disruptions"
import { EmberApiError } from "api/errors"

import { Filter, RadioFilterField } from "components/generic/filter-menu"
import { MultiColumnLayout } from "components/generic/multi-column-layout"

import { Disruption } from "types/location"

import { useGlobalFetcher } from "utils/state-utils"
import {
  updateQueryParam,
  updateStateFromUrl,
  useQueryString,
} from "utils/url-utils"

import DisruptionDetails from "./disruption-details"
import DisruptionModal from "./disruption-modal"
import { DisruptionSidebarList } from "./disruption-sidebar-list"

export enum DisruptionFilter {
  ALL = "all",
  CURRENT = "current",
  UPCOMING = "upcoming",
  PAST = "past",
}

const Disruptions = () => {
  const { data: locations } = useGlobalFetcher("locations")
  const [disruptions, setDisruptions] = useState<Disruption[]>([])
  const [filteredDisruptions, setFilteredDisruptions] =
    useState<Disruption[]>(disruptions)
  const [displayDisruptionModal, setDisplayDisruptionModal] = useState(false)

  const [disruptionFilter, setDisruptionFilter] = useState<DisruptionFilter>(
    DisruptionFilter.CURRENT
  )

  const handleSelectDisruptionType = (newSelectedDisruptionType) => {
    setDisruptionFilter(newSelectedDisruptionType)
  }

  function updateDisruptionFilter(newFilter: DisruptionFilter) {
    setDisruptionFilter(newFilter)
    updateQueryParam(queryString, { tag: "type", value: newFilter })
    setFullRefreshCount((count) => count + 1)
  }

  const filterOptions: RadioFilterField[] = [
    {
      type: "radio",
      name: "disruptions",
      title: "Disruptions",
      options: [
        { label: "All", value: DisruptionFilter.ALL },
        { label: "Current", value: DisruptionFilter.CURRENT },
        { label: "Upcoming", value: DisruptionFilter.UPCOMING },
        { label: "Past", value: DisruptionFilter.PAST },
      ],
      state: disruptionFilter,
      setState: updateDisruptionFilter,
    },
  ]

  const [selectedDisruptionId, setSelectedDisruptionId] = useState(null)

  const [loadingDisruptions, setLoadingDisruptions] = useState<boolean>(false)
  const [error, setError] = useState<EmberApiError>(null)

  const [fullRefreshCount, setFullRefreshCount] = useState(1)

  const [filterString, setFilterString] = useState<string>("")

  const { queryString } = useQueryString()
  const [loadedQueryString, setLoadedQueryString] = useState(false)

  useEffect(() => {
    if (queryString != null && !loadedQueryString) {
      setLoadedQueryString(true)
      updateStateFromUrl(queryString, urlParams)
    }
  }, [queryString])

  useEffect(() => {
    if (loadedQueryString) {
      fullRefresh()
    }
  }, [loadedQueryString, fullRefreshCount])

  const handleSelectDisruption = (newSelectedDisruptionId) => {
    setSelectedDisruptionId(newSelectedDisruptionId)
    updateQueryParam(queryString, {
      tag: "disruption",
      value: newSelectedDisruptionId,
    })
  }

  const handleUnselectDisruption = () => {
    setSelectedDisruptionId(null)
    updateQueryParam(queryString, { tag: "disruption", value: "" })
  }

  const urlParams = [
    {
      tag: "disruption",
      setState: handleSelectDisruption,
    },
    {
      tag: "type",
      setState: handleSelectDisruptionType,
    },
  ]

  async function fullRefresh() {
    setLoadingDisruptions(true)
    await loadDisruptions()
    setLoadingDisruptions(false)
  }

  function loadDisruptions() {
    setError(null)
    return new Promise<void>((resolve) => {
      fetchDisruptions({
        request: {
          filter: disruptionFilter,
        },
        onSuccess: (disruptions: Disruption[]) => {
          setDisruptions(disruptions)
          resolve()
        },
        onError: (error: EmberApiError) => {
          setError(error)
          resolve()
        },
      })
    })
  }

  function updateOneDisruption(disruption: Disruption) {
    // This is called when the disruption details component has refreshed disruption
    // data. Rather than re-fetching and re-loading the whole menu, we merge
    // the updated data into the existing list
    setDisruptions((disruptions) =>
      disruptions.map((d) => (d.uid == disruption.uid ? disruption : d))
    )
  }

  useEffect(() => {
    const newFilteredDisruptions: Disruption[] = []
    for (const disruption of disruptions) {
      if (filterString === "") {
        newFilteredDisruptions.push(disruption)
      } else if (
        disruption.title.toLowerCase().includes(filterString.toLowerCase()) ||
        disruption.description
          .toLowerCase()
          .includes(filterString.toLowerCase())
      ) {
        newFilteredDisruptions.push(disruption)
      }
    }
    setFilteredDisruptions(newFilteredDisruptions)
  }, [JSON.stringify(disruptions), filterString])

  return (
    <>
      <MultiColumnLayout
        detailSelected={selectedDisruptionId != null}
        optionName="disruptions"
        unselectDetail={() => setSelectedDisruptionId(null)}
        sidebarHeader={
          <Stack>
            <Stack direction="row" align="center" justify="between" grow>
              <InputField
                placeholder="Search..."
                value={filterString}
                autoComplete="off"
                onChange={(event) => {
                  const value = event.target.value
                  setFilterString(value)
                }}
              />
              <Stack direction="row" spacing="small" inline justify="end">
                <Filter
                  filters={filterOptions}
                  isActive={disruptionFilter !== DisruptionFilter.ALL}
                  displayClearFilters={false}
                />
                <Button
                  type="secondary"
                  onClick={() => setDisplayDisruptionModal(true)}
                  iconLeft={<Plus />}
                  loading={false}
                />
              </Stack>
            </Stack>
          </Stack>
        }
        sidebarContent={
          loadingDisruptions ? (
            <Loading type="boxLoader" text="Loading disruptions..." />
          ) : error ? (
            <Alert type="critical" title="Could not load disruptions">
              <Stack>
                <Text>
                  Check you are logged in with the right permissions and your
                  internet connection is active. The error message was "
                  {error.message}
                  ".
                </Text>
                <AlertButton
                  type="criticalSubtle"
                  onClick={() => setFullRefreshCount(fullRefreshCount + 1)}
                >
                  Try again
                </AlertButton>
              </Stack>
            </Alert>
          ) : (
            <DisruptionSidebarList
              disruptions={filteredDisruptions}
              selectedDisruptionId={selectedDisruptionId}
              handleSelectDisruption={handleSelectDisruption}
            />
          )
        }
        detailSection={
          <DisruptionDetails
            disruptionUid={selectedDisruptionId}
            fullRefreshCount={fullRefreshCount}
            triggerFullRefresh={() => setFullRefreshCount(fullRefreshCount + 1)}
            allowAdminActions={true}
            handleReturnToAllDisruptions={handleUnselectDisruption}
            updateOneDisruption={updateOneDisruption}
            locations={locations}
          />
        }
      />
      {displayDisruptionModal && (
        <DisruptionModal
          disruption={null}
          handleClose={() => setDisplayDisruptionModal(false)}
          handleUpdate={(disruption) => {
            if (disruption) {
              handleSelectDisruption(disruption.uid)
              updateDisruptionFilter(DisruptionFilter.ALL)
            }
          }}
        />
      )}
    </>
  )
}

export default Disruptions
