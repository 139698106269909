// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Form, Formik } from "formik"

import {
  Button,
  Heading,
  InputField,
  Loading,
  Popover,
  Stack,
  TextLink,
} from "@kiwicom/orbit-components"
import { Filters } from "@kiwicom/orbit-components/icons"

import {
  EmberCheckboxGroup,
  EmberCheckboxOptionProps,
  EmberRadioGroup,
  EmberRadioOptionProps,
} from "./formik-inputs"

export interface CheckboxFilterField {
  type: "checkbox"
  name: string
  title: string
  options: EmberCheckboxOptionProps[]
  state: string[]
  setState: (value: string[]) => void
}

export interface RadioFilterField {
  type: "radio"
  name: string
  title: string
  options: EmberRadioOptionProps[]
  state: string
  setState: (value: string) => void
}

export interface FreeTextFilterField {
  type: "freeText"
  name: string
  title: string
  state: string
  setState: (value: string) => void
}

type FilterField = CheckboxFilterField | RadioFilterField | FreeTextFilterField

const takesMultipleValues = (filter: FilterField) => filter.type == "checkbox"

export const renderFilter = (filter: FilterField) => {
  if (filter.type == "freeText") {
    return (
      <InputField
        value={filter.state}
        onChange={(event) => filter.setState(event.currentTarget.value)}
      />
    )
  } else if (filter.type == "checkbox") {
    return <EmberCheckboxGroup name={filter.name} options={filter.options} />
  } else {
    return <EmberRadioGroup name={filter.name} options={filter.options} />
  }
}

interface FilterProps {
  filters: FilterField[]
  smallIcon?: boolean
  loading?: boolean
  displayClearFilters?: boolean
  isActive?: boolean
}

export const Filter = ({
  filters = [],
  smallIcon = false,
  loading = false,
  displayClearFilters = true,
  isActive,
}: FilterProps) => {
  const [openFiltersPopover, setOpenFiltersPopover] = useState(false)
  const checkboxFilters = filters.filter(
    (f): f is CheckboxFilterField => f.type == "checkbox"
  )

  useEffect(() => {
    // Remove selections that are no longer optional
    checkboxFilters.forEach((filter) => {
      const filterOptionsIds = filter.options.map((o) => o.value)
      const updatedState = filter.state.filter((s) =>
        filterOptionsIds.includes(s)
      )
      if (JSON.stringify(updatedState) !== JSON.stringify(filter.state)) {
        filter.setState(updatedState)
      }
    })
  }, [JSON.stringify(checkboxFilters.map((f) => f.options))])

  function filterIsActive() {
    // Active behaviour is overridden from the parent component.
    if (isActive !== undefined) {
      return isActive
    }

    if (checkboxFilters.some((filter) => filter.state.length > 0)) {
      return true
    } else {
      return false
    }
  }

  const initialValues = {}
  const filtersMap = {}
  const emptyFilter = {}
  filters.forEach((filter) => {
    initialValues[filter.name] = filter.state
    filtersMap[filter.name] = filter
    emptyFilter[filter.name] = takesMultipleValues(filter) ? [] : filter.state
  })

  return (
    <Popover
      opened={openFiltersPopover}
      onClose={() => setOpenFiltersPopover(false)}
      width="240px"
      maxHeight="600px"
      content={
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            Object.keys(values).forEach((key) => {
              filtersMap[key].setState(values[key])
            })
          }}
          enableReinitialize
          validateOnChange={true}
          onSubmit={() => null}
        >
          {({ setValues }) => (
            <Form>
              <Stack>
                {loading ? (
                  <Loading type="inlineLoader" text="Loading Filters" />
                ) : (
                  <>
                    {filters.map((filter) => (
                      <Stack key={filter.name}>
                        <Heading type="title5">{filter.title}</Heading>
                        {renderFilter(filter)}
                      </Stack>
                    ))}
                    {filterIsActive() && displayClearFilters && (
                      <TextLink
                        size="small"
                        type="secondary"
                        onClick={() => {
                          setValues(emptyFilter)
                        }}
                      >
                        Clear Filters
                      </TextLink>
                    )}
                  </>
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      }
    >
      <Button
        type={filterIsActive() ? "primary" : "secondary"}
        onClick={() => setOpenFiltersPopover(true)}
        size={smallIcon ? "small" : "normal"}
        iconLeft={<Filters />}
      />
    </Popover>
  )
}
